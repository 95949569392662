import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { ObjectDataFacade } from '@domains/object';

import { DynamicFormService } from 'src/app/common-components/dynamic-form/dynamic-form.service';
import { DynamicFieldConfig } from 'src/app/common-components/dynamic-form/types';

@Injectable({
  providedIn: 'root',
})
export class PropertyCustomStepService {
  private readonly _customSteps$$ = new BehaviorSubject<any[]>([]);

  readonly customSteps$: Observable<{
    key: string;
    label: string;
    fields: DynamicFieldConfig[];
    form: FormGroup;
  }[]> = this._customSteps$$.asObservable();

  constructor(
    private readonly _objectDataFacade: ObjectDataFacade,
    private readonly _dynamicFormService: DynamicFormService
  ) {
    this.initCustomStepsGeneration();
  }

  initCustomStepsGeneration(): void {
    this._objectDataFacade.propertyExtensionConfig$.pipe(
      distinctUntilChanged(),
      tap((config) => {
        if (config) {
          const customSteps = config.groups.map((group: any) => ({
            label: group.label,
            key: group.key,
            fields: group.fields,
            form: this._dynamicFormService.buildFormFromConfig(group.fields),
          }));
          this._customSteps$$.next(customSteps);
        }
      })
    ).subscribe();
  }

  addCustomStepsToForm(customContentForm: FormGroup): void {
    const customSteps = this._customSteps$$.value;
    if (customSteps) {
      for (const step of customSteps) {
        customContentForm.setControl(step.key, step.form);
      }
    }
  }
}