import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { SnackbarService } from 'services/snackbar.service';
import { LegacyMapperService } from './mapper/legacy-mapper.service';
import {
  CadastralCommunity,
  Object,
  ObjectDocument,
  ObjectSettings,
  ObjectStatus,
  ObjectsCountByAgent,
  PropertyActivity,
  PropertySharing,
  SearchObjectsResult, UserMappings, UpdateUserMapping,
  PropertyExtensionConfig,
} from './object.model';
import { PropertyDetailsForAgreements } from '../exclusive-agreement';

@Injectable({ providedIn: 'root' })
export class ObjectApiFacade {
  constructor(
    private readonly apollo: Apollo,
    private legacyMapper: LegacyMapperService,
    private readonly _snackbar: SnackbarService
  ) { }

  loadCadastralCommunities$(): Observable<CadastralCommunity[]> {
    return this.apollo
      .query({
        query: gql`
            query GetCadastralCommunities {
              cadastralCommunities {
                KGNr
                KGName
                PGNr
                PolName
              }
            }
        `,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        first(),
        map((result: any) => result.data.cadastralCommunities)
      )
  }

  loadObjectDetails$(id: string): Observable<Object> {
    return this.apollo
      .query({
        query: gql`
          query GetDetails($objectId: String!) {
            object(id: $objectId) {
              ...objectFragment
            }
          }
          ${objectFragment}
        `,
        variables: {
          objectId: id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result: ApolloQueryResult<any>) =>
          this.legacyMapper.mapLegacyObjectDetails(result.data.object)
        )
      );
  }

  getObjectRelatedDocuments$(objectId: string): Observable<ObjectDocument[]> {
    return this.apollo.query({
      query: gql`
          query GetObjectRelatedDocuments($objectId: String!) {
            objectRelatedDocuments(id: $objectId) {
              id
              title
              description
              storageUrl
              type
              documentFrom
            }
          }
        `,
      variables: {
        objectId,
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: ApolloQueryResult<any>) => result.data.objectRelatedDocuments)
    );
  }

  loadPropertySharing$(objectId: string): Observable<PropertySharing[]> {
    return this.apollo.query({
      query: gql`
        query GetPropertySharings($objectId: String!) {
          propertySharing(objectId: $objectId) {
            id
            userId
            user {
              firstname
              lastname
              email
            }
          }
        }
      `,
      variables: {
        objectId,
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: ApolloQueryResult<any>) => result.data.propertySharing)
    );
  }

  loadPropertyActivities$(objectId: string): Observable<PropertyActivity[]> {
    return this.apollo.query({
      query: gql`
        query GetPropertyActivities($objectId: String!) {
          propertyActivities(objectId: $objectId) {
            id
            objectId
            type
            source
            description
            createdBy
            createdByAgent {
              firstname
              lastname
              email
            }
            customers {
              id
              firstname
              lastname
            }
            customerIds
            activityFile {
              url
              title
              description
            }
            createdAt
          }
        }
      `,
      variables: {
        objectId,
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: ApolloQueryResult<any>) => result.data.propertyActivities)
    );
  }

  loadPropertyExtensionConfig$(): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetPropertyExtensionConfig {
          propertyExtensionConfig {
            id
            groups {
              key
              label
              fields {
                key
                label
                type
                placeholder
                options {
                  value
                  label
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: ApolloQueryResult<any>) => result.data.propertyExtensionConfig)
    );
  }

  savePropertyExtensionConfig$(config: PropertyExtensionConfig): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SavePropertyExtensionConfig($config: PropertyExtensionConfigInput!) {
          propertyExtensionConfig(config: $config)
        }
      `,
      variables: { config },
      fetchPolicy: 'no-cache',
    }).pipe(
      catchError(() => of(false)),
      map(() => true),
    );
  }

  syncObjectDetails$(id: string): Observable<Object> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SyncObjectDetails($objectId: String!) {
          syncObject(id: $objectId) {
            ...objectFragment
          }
        }
        ${objectFragment}
      `,
      variables: { objectId: id },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: any) => this.legacyMapper.mapLegacyObjectDetails(result.data.syncObject)),
    );
  }

  updateOnOfficeProperty$(propertyId: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateOnOfficeProperty($propertyId: String!) {
          updateOnOfficeProperty(propertyId: $propertyId)
        }
      `,
      variables: { propertyId },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: any) => result.data.updateOnOfficeProperty),
    );
  }

  createPropertySharing$(objectId: string, email: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation CreatePropertySharing($objectId: String!, $email: String!) {
          createPropertySharing(objectId: $objectId, email: $email)
        }
      `,
      variables: {
        objectId,
        email,
      },
    }).pipe(map((result: any) => result.data.createPropertySharing));
  }

  importOnOfficeProperty$(importId: string): Observable<string> {
    return this.apollo.mutate({
      mutation: gql`
        mutation ImportOnOfficeProperty($importId: String!) {
          importOnOfficeProperty(importId: $importId)
        }
      `,
      variables: { importId },
      fetchPolicy: 'no-cache',
    }).pipe(map((result: any) => result.data.importOnOfficeProperty));
  }

  deletePropertySharing$(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeletePropertySharing($id: String!) {
          deletePropertySharing(id: $id)
        }
      `,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: any) => result.data.deletePropertySharing),
    );
  }

  loadExternalObjectDetails$(id: string): Observable<Object> {
    return this.apollo
      .query({
        query: gql`
          query GetExternalObjectDetails($objectId: String!) {
            externalObject(id: $objectId) {
              title
              objectType
              orderType
              address {
                country
                city
                state
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
                longitude
                latitude
              }
              roomsAndArea {
                livingArea {
                  area
                  areaCa
                }
                baseArea {
                  area
                  areaCa
                }
                usableArea {
                  area
                  areaCa
                }
              }
              price
              additionalInformation {
                constructionYear
                constructionYearCa
                cadastralCommunity
                entryNumber
                parcelNumber
                parcelNumbers
              }
              externalObjectNumber
              externalObjectDisplayNumber
              externalObjectType
              externalState
              externalSystem
              agent {
                fullname
              }
              rooms
              priceDetails {
                operatingCosts
              }
              rentalSpecifications {
                hasLeasingTerm
                rentalDeposit {
                  depositAmount
                }
              }
            }
          }
        `,
        variables: {
          objectId: id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => {
          const objectData = result.data.externalObject;
          objectData.id = result.data.externalObject.externalObjectNumber;
          return this.legacyMapper.mapLegacyObjectDetails(objectData);
        })
      );
  }

  loadUserMappings$(): Observable<UserMappings> {
    return this.apollo
      .query({
        query: gql`
        query GetUserMappings {
          userMappings {
            userId
            mapping {
              field
              fieldMapping
              label
              type
              permittedValues {
                key
                value
              }
            }
          }
        }
      `,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => {
          return result.data.userMappings
        })
      );
  }

  loadPropupPropertyModel$(): Observable<any> {
    return this.apollo
      .query({
        query: gql`
        query GetPropupPropertyModel {
          propupPropertyModel {
            userId
            fieldsConfig {
              field
              type
              label
            }
          }
        }
      `,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => {
          return result.data.propupPropertyModel.fieldsConfig;
        })
      );
  }

  updateUserMapping$(modifications: UpdateUserMapping): Observable<UserMappings> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation UpdateUserMappings($input: UserMappingsInput!) {
          updateUserMappings(modifications: $input) {
            userId
            mapping {
              field
              fieldMapping
              label
              type
              permittedValues {
                key
                value
              }
            }
          }
        }
      `,
        variables: {
          input: modifications
        },
      })
      .pipe(
        map((result: any) => {
          return result.data.updateUserMappings;
        })
      );
  }

  loadAvailableAgents$(): Observable<ObjectsCountByAgent[]> {
    return this.apollo.query({
      query: gql`
        query GetAvailableAgents {
          availableAgentNames {
            name
            count
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(map((result: ApolloQueryResult<any>) => result.data.availableAgentNames));
  }

  loadObjectsByAgent$(agentName?: string): Observable<Object[]> {
    return this.apollo.query({
      query: gql`
        query GetObjectSummary($agentName: String) {
          objectsByAgent(agentName: $agentName) {
            ...objectItemFragment
          }
        }
        ${objectItemFragment}
      `,
      variables: {
        agentName,
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: ApolloQueryResult<any>) =>
        (result.data.objectsByAgent as [])
          .filter((obj: any) => obj.externalObjectType !== 'Lebkuchenhaus')
          .map((obj) => this.legacyMapper.mapLegacyObjectDetails(obj))
      )
    );
  }

  searchObjects$(searchText = ''): Observable<Record<string, Object[]>> {
    return this.apollo.query({
      query: gql`
        query SearchObjects($searchText: String!) {
          searchObjects(searchText: $searchText) {
            name
            objects {
              ...objectItemFragment
            }
          }
        }
        ${objectItemFragment}
      `,
      variables: {
        searchText,
      },
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: ApolloQueryResult<any>) => result.data.searchObjects),
      map((searchResults) => {
        const result: Record<string, Object[]> = {};

        searchResults.forEach((searchResult: SearchObjectsResult) => {
          result[searchResult.name] = searchResult.objects
            .filter((obj: any) => obj.externalObjectType !== 'Lebkuchenhaus')
            .map((obj) => this.legacyMapper.mapLegacyObjectDetails(obj));
        });

        return result;
      }),
    );
  }

  createObject$(data: any): Observable<Object> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation CreateObject($object: CreateObject!) {
            object(object: $object) {
              ...objectFragment
            }
          }
          ${objectFragment}
        `,
        variables: {
          object: data,
        },
      })
      .pipe(map((result: any) => result.data.object));
  }

  saveProperty$(data: any): Observable<Object> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation SaveProperty($object: CreateObject!) {
          object(object: $object) {
            ...objectFragment
          }
        }
        ${objectFragment}
      `,
        variables: {
          object: data,
        },
      })
      .pipe(map((result: any) => result.data.object));
  }

  updateObject$(data: Object): Observable<Object> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation UpdateObject($object: UpdateObject!) {
            updateObject(object: $object) {
              ...objectFragment
            }
          }
          ${objectFragment}
        `,
        variables: {
          object: data,
        },
      })
      .pipe(map((result: any) => result.data.updateObject));
  }

  updatePropertyPartially$(data: Partial<Object>): Observable<Object> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation UpdatePropertyPartially($object: UpdatePropertyPartially!) {
          updatePropertyPartially(object: $object) {
            ...objectFragment
          }
        }
        ${objectFragment}
      `,
        variables: {
          object: data,
        },
      })
      .pipe(map((result: any) => result.data.updatePropertyPartially));
  }

  linkEdirealObject$(id: string, externalId: string): Observable<Object> {
    return this.apollo.mutate({
      mutation: gql`
        mutation LinkEdirealObject($id: String!, $externalId: String!) {
          linkEdirealObject(id: $id, externalId: $externalId) {
            ...objectFragment
          }
        }
        ${objectFragment}
      `,
      variables: { id, externalId },
    }).pipe(
      map((result: any) => result.data.linkEdirealObject),
    );
  }

  updatePropertyDetailsOfObject$(objectId: string, data: PropertyDetailsForAgreements): Observable<Object> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation UpdatePropertyDetailsOfObject($objectId: String!, $propertyDetails: PropertyDetailsForAgreementsInput!) {
            propertyDetailsForAgreements(objectId: $objectId, propertyDetails: $propertyDetails) {
              property {
                use
                constructionYear
                constructionYearCa
                cadastralCommunity
                entryNumber
                parcelNumber
                parcelNumbers
                movingInDate
                propertyType
                propertyTypeNotes
                shareInOwnership
              }
              rulesOfUse
              usageBoundCosts {
                costs
                electricity
                gas
                heating
                telecommunication
                water
                other
                otherDetails
              }
            }
          }
          ${objectFragment}
        `,
        variables: {
          objectId: objectId,
          propertyDetails: data,
        },
      })
      .pipe(map((result: any) => result.data.updateObject));
  }

  updateObjectStatus$(
    id: string,
    objectStatus: ObjectStatus
  ): Observable<Object> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation UpdateObjectStatus($objectId: String!, $objectStatus: String!) {
            updateObjectStatus(
              objectId: $objectId
              objectStatus: $objectStatus
            ) {
              ...objectFragment
            }
          }
          ${objectFragment}
        `,
        variables: {
          objectId: id,
          objectStatus: objectStatus,
        },
      })
      .pipe(map((result: any) => result.data.updateObjectStatus));
  }

  deleteObjects$(ids: string[]): Observable<Object[]> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation DeleteObjects($objectIds: [String!]!) {
            deleteObjects(objectIds: $objectIds) {
              ...objectFragment
            }
          }
          ${objectFragment}
        `,
        variables: {
          objectIds: ids,
        },
      })
      .pipe(
        map((result: any) =>
          (result.data.deleteObjects as []).map((obj) =>
            this.legacyMapper.mapLegacyObjectDetails(obj)
          )
        )
      );
  }

  importEdirealObject$(edirealPropertyId: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ImportEdiRealObject($edirealPropertyId: String!) {
            importEdirealObject(edirealPropertyId: $edirealPropertyId)
          }
        `,
        variables: {
          edirealPropertyId,
        },
      })
      .pipe(
        map((result: any) => result.data.importEdirealObject),
        tap((response) => {
          if (response) {
            this._snackbar.showSnackbar(
              'Objekt erfolgreich importiert',
              'mat-primary',
              true
            );
          } else {
            this._snackbar.showSnackbar(
              'Objekt konnte nicht importiert werden',
              'mat-warn',
              true
            );
          }
        })
      );
  }

  createObjectSettings$(objectId: string): Observable<ObjectSettings> {
    return this.apollo.mutate({
      mutation: gql`
        mutation CreateObjectSettings($objectId: String!) {
          objectSettings(objectId: $objectId) {
            objectId
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              legalEntity
              phone
            }
            rentalOfferSettings {
              allowPublicAccess
              allSubmitRentalOffer
              mustCompleteCreditCheck
              mustFillCustomerQuestionnaire
              mustHaveUploads {
                title
                description
                mandatory
              }
            }
            buyingOfferSettings {
              allAskForSufficientFinance
              allAskForMoneyLaundering
              allAskForPep
              allSubmitBuyingOffer
              mustHaveUploads {
                title
                description
                mandatory
              }
            }
            viewingPassSettings {
              allSubmitViewingPass
            }
            generalSettings {
              accessToFinanceCalculator
              accessToAllDocuments
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(map((result: any) => result.data.objectSettings));
  }

  getObjectSettings$(objectId: string): Observable<ObjectSettings> {
    return this.apollo.query({
      query: gql`
        query GetObjectSettings($objectId: String!) {
          objectSettings(objectId: $objectId) {
            objectId
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              legalEntity
              phone
            }
            rentalOfferSettings {
              allowPublicAccess
              allSubmitRentalOffer
              mustCompleteCreditCheck
              mustFillCustomerQuestionnaire
              mustHaveUploads {
                title
                description
                mandatory
              }
            }
            buyingOfferSettings {
              allAskForSufficientFinance
              allAskForMoneyLaundering
              allAskForPep
              allSubmitBuyingOffer
              mustHaveUploads {
                title
                description
                mandatory
              }
            }
            generalSettings {
              accessToFinanceCalculator
              accessToAllDocuments
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(map((result: any) => result.data.objectSettings));
  }

  updateObjectSettings$(objectId: string, settings: ObjectSettings): Observable<ObjectSettings> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateObjectSettings($objectId: String!, $settings: ObjectSettingsInput!) {
          updateObjectSettings(objectId: $objectId, settings: $settings) {
            objectId
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              legalEntity
              phone
            }
            rentalOfferSettings {
              allowPublicAccess
              allSubmitRentalOffer
              mustCompleteCreditCheck
              mustFillCustomerQuestionnaire
              mustHaveUploads {
                title
                description
                mandatory
              }
            }
            buyingOfferSettings {
              allAskForSufficientFinance
              allAskForMoneyLaundering
              allAskForPep
              allSubmitBuyingOffer
              mustHaveUploads {
                title
                description
                mandatory
              }
            }
            generalSettings {
              accessToFinanceCalculator
              accessToAllDocuments
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
          }
        }
      `,
      variables: { objectId, settings },
    }).pipe(map((result: any) => result.data.updateObjectSettings))
  }

  runPropertyDuplicationCheck$(): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation RunPropertyDuplicationCheck {
          runPropertyDuplicationCheck
        }
      `,
    }).pipe(map((result: any) => result.data.runPropertyDuplicationCheck))
  }
}

export const objectFragment = gql`
  fragment objectFragment on Object {
    id
    userId
    creationSource
    objectType
    orderType
    objectStatus
    creationDate
    externalObjectType
    externalState
    externalObjectNumber
    externalObjectDisplayNumber
    externalSystem
    modifiedInSystem
    description
    rooms
    movingInDate
    movingInDateDetails
    address {
      country
      city
      zipCode
      state
      streetName
      buildingNumber
      doorNumber
      stairway
      longitude
      latitude
    }
    roomsAndArea {
      livingArea {
        area
        areaCa
      }
      baseArea {
        area
        areaCa
      }
      usableArea {
        area
        areaCa
      }
      generalRoomsInformation {
        count
        floorAmount
        floor
        floorDescription
        elevator
      }
      toilets {
        roomCount
        area
        areaCa
      }
      bathRooms {
        roomCount
        area
        areaCa
      }
      storeRooms {
        roomCount
        area
        areaCa
      }
      garden {
        roomCount
        area
        areaCa
      }
      basement {
        roomCount
        area
        areaCa
      }
      balcony {
        roomCount
        area
        areaCa
      }
      loggia {
        roomCount
        area
        areaCa
      }
      terrace {
        roomCount
        area
        areaCa
      }
      parking {
        roomCount
        area
        areaCa
      }
      garage {
        roomCount
        area
        areaCa
      }
      otherRooms {
        title
        area
        areaCa
      }
    }
    condition {
      overall
      floor
      roof
      windows
      installer
      electric
      reconstruction
      reconstructionPlan
      reserveCoverage
      hiddenFlaws
      hiddenFlawsNotes
      offBookCharges
      offBookChargesNotes
    }
    inventoryList {
      title
      notes
    }
    propertyNotes {
      title
      notes
    }
    propertyAttributes {
      beaconing {
        alternative
        electro
        teleheating
        gas
        coal
        oil
        solar
        thermalPower
        geothermics
        fluidGas
        wood
        airHeatPump
        pellets
        waterElectro
      }
      heating {
        selfContainedCentralHeating
        floorHeating
        chimney
        teleheating
        centralHeating
      }
      floors {
        floorboards
        screed
        tiles
        syntheticFloor
        linoleum
        parquet
        carpet
        terracotta
        accessFloor
        finishedParquet
        granite
        laminate
        marble
        stone
        carpetTiles
      }
      structure {
        prefab
        solid
        brick
        wood
        ferroconcrete
      }
      connections {
        electricity
        highVoltage
        gas
        water
        fountain
        teleheating
        canal
        phone
        tvCabel
        tvSat
        internet
      }
    }
    energyPass {
      energyPassAvailable
      energyPassResponsible
      energyPassDelivery
      hwb
      hwbClass
      fGEE
      fgeeClass
      validUntil
    }
    price
    priceDetails {
      customerExpectedPrice
      reserve
      operatingCostStipulation
    }
    realtyPrice {
      purchase_price {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      rent {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      furniture_hire {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      parking {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      garage {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      operating {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      heating {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      hot_water {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      cooling {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      maintenance_funds {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      elevator {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
      others {
        currency
        net
        gross
        vat_absolute
        vat_percentage
      }
    }
    rentalSpecifications {
      rentalUnit
      rentalUnitFloor
      rentalNumberTransferee
      rentDurationDetails
      maximumRentalPeriod
      unlimitedRentalPeriod
      waiverOfCancellationPeriod
      drafterOfDeedsCosts
      rentalDeposit {
        depositDescription
      }
    }
    preListingData {
      hasValuationLight
      hasValuationFull
      hasLocationScore
      hasPOIs
      hasAccessibility
      hasDossier
      hasContaminatedSites
      hasHORAPass
      hasMaps
    }
    valuationLightData {
      confidence
      currency
      valueRange {
        lower
        upper
      }
    }
    valuationBuyFullData {
      confidence
      currency
      value
      valueRange {
        lower
        upper
      }
      salePrice
      salePriceRange {
        lower
        upper
      }
    }
    valuationRentFullData {
      confidence
      currency
      value
      valueRange {
        lower
        upper
      }
      rentNet
      rentNetRange {
        lower
        upper
      }
      rentGross
      rentGrossRange {
        lower
        upper
      }
    }
    additionalInformation {
      constructionYear
      constructionYearCa
      cadastralCommunity
      entryNumber
      parcelNumber
      parcelNumbers
    }
    locationScoreData {
      health
      leisure
      family
      shopping
      catering
    }
    dossierLink {
      id
      url
    }
    preListingReport {
      url
      creationDate
    }
    agent {
      id
      fullname
      email
      firstName
      lastName
      phone
      isRemax
      themeConfig
      picture
      officeName
      officeAddress
      legalEntity
    }
    citywalxLink {
      id
      address
      expire
      link
    }
    documents {
      id
      title
      description
      storageUrl
      type
    }
    neededDocuments {
      landZoning
      developmentPlan
      permitOfUsePlan
      buildingPermit
      floorPlan
      operatingCosts
      otherDocs {
        description
        docStatus
      }
    }
    images {
      id
      storageUrl
      type
      description
      order
    }
    ownerType
    owners {
      customerId
      birthdate
      title
      firstname
      lastname
      nationality
      contact {
        address {
          country
          city
          zipCode
          streetName
          buildingNumber
          doorNumber
          stairway
          longitude
          latitude
        }
        phone
        email
      }
      IDImages {
        id
        storageUrl
        type
        description
      }
    }
    businessOwner {
      customerId
      customerData {
        id
        companyNumber
        companyRegister
        contact {
          address {
            country
            city
            zipCode
            streetName
            buildingNumber
            doorNumber
            stairway
          }
          phone
          email
        }
        customers {
          id
          birthdate
          firstname
          lastname
          nationality
          contact {
            address {
              country
              city
              zipCode
              streetName
              buildingNumber
              doorNumber
              stairway
              longitude
              latitude
            }
            phone
            email
          }
          IDImages {
            id
            storageUrl
            type
            description
          }
        }
      }
    }
    platformExports {
      maklerSalzburgIsActive
    }
    customContent
  }
`;

export const objectItemFragment = gql`
  fragment objectItemFragment on Object {
    id
    userId
    creationSource
    objectType
    orderType
    objectStatus
    creationDate
    externalObjectType
    externalState
    externalObjectNumber
    externalObjectDisplayNumber
    externalSystem
    modifiedInSystem
    address {
      country
      city
      zipCode
      state
      streetName
      buildingNumber
      doorNumber
      stairway
      longitude
      latitude
    }
    agent {
      id
      fullname
      email
      firstName
      lastName
      phone
    }
    images {
      id
      storageUrl
      type
      description
      order
    }
  }
`;
