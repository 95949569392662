import { createAction, props } from '@ngrx/store';
import { CustomerWithContact } from '../customer.model';
import { PagingOptions } from 'src/app/common-models/paging-options';
import { ListData } from 'src/app/common-models/list-data';

export const LoadCustomers = createAction(
  '[Customer] Load Customers',
  props<{
    pagingOptions: PagingOptions,
    searchText?: string,
  }>(),
);
export const LoadCustomersFailed = createAction('[Customer] Failed to load Customers');
export const LoadCustomersSucceeded = createAction(
  '[Customer] Succeeded to load Customers',
  props<{ payload: ListData<CustomerWithContact> }>()
);

export const LoadCustomerDetails = createAction(
  '[Customer] Load Customer',
  props<{ payload: { id: string } }>()
);
export const LoadCustomerFailed = createAction('[Customer] Failed to load Customer');
export const LoadCustomerSucceeded = createAction(
  '[Customer] Succeeded to load Customer',
  props<{ payload: CustomerWithContact }>()
);

export const TriggerExternalCustomersLoadingSucceeded = createAction(
  '[Customer] Succeeded To Trigger External Customers Loading'
);

export const SaveRentalContractCustomers = createAction(
  '[Customers] Save Rental Contract Customers',
  props<{
    contractTenants: CustomerWithContact[];
    contractLandlords: CustomerWithContact[];
    rentalContractId: string;
    objectId: string;
  }>()
);

export const StartCustomersDuplicationCheck = createAction(
  '[Customer] Start Customers Duplication Check',
);

export const FinishCustomersDuplicationCheck = createAction(
  '[Customer] Finish Customers Duplication Check',
  props<{ payload: { hasUpdate: boolean } }>(),
);

export const ResetCustomersDuplicationCheck = createAction(
  '[Customer] Reset Customers Duplication Check',
);

export const DeleteCustomer = createAction('[Customer] Delete Customer', props<{ customerId: string }>());
export const DeleteCustomerSuccess = createAction('[Customer] Delete Customer Succeeded', props<{ customerId: string }>());
export const DeleteCustomerFailed = createAction('[Customer] Delete Customer Failed', props<{ customerId: string }>());
